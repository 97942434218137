label.required
	position relative
	&::after
		content "*"
		position absolute
		right -15px
		color #f00
		font-weight bold
		font-size 25px
		line-height 1
		top 0
		bottom 0
select
	width 100%
